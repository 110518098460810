












































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Pagination from '@bertazzoni/back/components/Pagination.vue'
import CheckBox from '@bertazzoni/common/src/components/CheckBox.vue'
import RadioButton from '@bertazzoni/common/src/components/RadioButton.vue'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import { POINT_BY_QUESTION } from '@bertazzoni/common/src/helpers/utils/utils'
import { UserType } from '@bertazzoni/common/src/models/user.model'
import { TableInfo, CategoryObjectType, ObjectType } from '@bertazzoni/back/models/table.model'
import { QuestionType } from '@bertazzoni/common/src/models/Question'
import store from '@bertazzoni/common/src/store/index'
import draggable from 'vuedraggable'

@Component({
  components: { Pagination, Loader, Icons, CheckBox, RadioButton, draggable }
})
export default class GenericTable extends Vue {
  @Prop({ required: true }) data!: Array<any>
  @Prop({ required: true }) objectType!: string
  @Prop({ default: null }) emptyMessage!: string
  @Prop({ required: true }) tableInfo!: TableInfo
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: () => [] }) selectedList!: string[]
  @Prop({ default: () => [] }) questionAvgScore!: number[]

  private limitList = [1, 5, 10, 15, 20]
  private userRight = this.tableInfo.getUserRight()
  private currentUser = store.getters['User/currentUser']
  private customTable = this.tableInfo.getCustomTable()
  private paginInfo = this.tableInfo.getPaginInfo()
  private filterKey = ''
  private points = POINT_BY_QUESTION
  private limit: number = this.paginInfo.limit
  private offset: number = this.paginInfo.offset
  private language = ''
  private maxVisibleButtons = 5
  private categoryObjectType = CategoryObjectType
  private questionType = QuestionType
  private objectTypeEnum = ObjectType
  private userTypes = UserType

  @Watch('limit')
  limitChanged(newValue: number): void {
    this.limit = newValue
    this.$emit('new-limit', newValue)
    this.changePage(1)
  }
  get totalPages(): number {
    return Math.ceil(this.paginInfo.totalRow / this.limit)
  }
  get hasMorePages(): boolean {
    return this.totalPages - this.paginInfo.currentPage > this.maxVisibleButtons - 2
  }
  getOffset(currentPage: number): number {
    return (currentPage - 1) * this.limit
  }
  selectRow(newValue: boolean, selectedId: string): void {
    this.$emit('select-object', newValue, selectedId)
  }
  changeQuestionId(): void {
    if (this.objectType === this.objectTypeEnum.moduleQuizEdit) {
      this.$emit('rename-question-id')
    }
    if (this.objectType === this.objectTypeEnum.moduleContentEdit) {
      this.$emit('reorder-contents')
    }
  }
  isUser(entry): boolean {
    let isUser = false
    entry.objectType === this.objectTypeEnum.countryAdmin ||
    entry.objectType === this.objectTypeEnum.distributorRep ||
    entry.objectType === this.objectTypeEnum.salesRep ||
    entry.objectType === this.objectTypeEnum.seller ||
    entry.objectType === this.objectTypeEnum.user
      ? (isUser = true)
      : (isUser = false)
    return isUser
  }
  dataNotEmpty(): boolean {
    return this.data && this.data.length > 0
  }
  changePage(newCurrentPage: number): void {
    this.offset = this.getOffset(newCurrentPage)
    this.paginInfo.currentPage = newCurrentPage
    this.$emit('new-page', this.offset, newCurrentPage)
  }
  languageChanged(): void {
    if (this.language === 'None') this.language = ''
    this.$emit('new-language', this.language)
  }
  searchChanged(): void {
    this.$emit('new-search', this.filterKey)
  }
  deleteObject(id: string, name: string) {
    this.$emit('delete-object', id, name)
  }
  associateObject(id: string) {
    this.$emit('associate-object', id)
  }
  enableObject(id: string, name: string) {
    this.$emit('enable-object', id, name)
  }
  disableObject(id: string, name: string) {
    this.$emit('disable-object', id, name)
  }
  updateObject(object: any) {
    this.$emit('update-object', object)
  }
  consultObject(id: string) {
    this.$emit('consult-object', id)
  }
  addNewObject() {
    this.$emit('add-object')
  }
}
