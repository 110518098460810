export enum QuestionType {
  qcm = 'QCM',
  qcu = 'QCU',
  vf = 'V/F'
}
export class Question {
  id: number
  title: string
  type: QuestionType
  proposals: string[]
  indexResponses: number | number[]
  userResponses?: number | number[]

  constructor(
    id?: number,
    title = '',
    type: QuestionType = '' as QuestionType,
    proposals: string[] = ['', ''],
    indexResponses: number | number[] = [0],
    userResponses: number | number[] = [0],
  ) {
    if (id) this.id = id
    this.type = type
    this.title = title
    this.proposals = proposals
    this.indexResponses = indexResponses
    this.userResponses = userResponses
  }

  public getId() {
    return this.id
  }

  public getTitle() {
    return this.title
  }

  public getType() {
    return this.type
  }

  public getProposals() {
    return this.proposals
  }

  public getProposalById(index: number) {
    return this.proposals[index]
  }

  public getIndexResponses() {
    return this.indexResponses
  }
  public getUserResponses() {
    return this.userResponses
  }
}
export interface UserResponse {
  id: number
  indexResponses: number | number[]
}
