



























































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Pagination extends Vue {
  @Prop({ required: true }) totalPages!: number
  @Prop({ required: true }) perPage!: number
  @Prop({ required: true }) currentPage!: number
  @Prop({ required: true }) hasMorePages!: boolean
  @Prop({ required: true }) maxVisibleButtons!: number

  get startPage(): number {
    if (this.currentPage === 1) {
      return 1
    }
    if (this.currentPage === this.totalPages && this.totalPages > this.maxVisibleButtons) {
      return this.totalPages - this.maxVisibleButtons + 1
    }
    return this.currentPage - 1
  }
  get endPage(): number {
    return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages)
  }
  get isInFirstPage(): boolean {
    return this.currentPage === 1
  }
  get isInLastPage(): boolean {
    return this.currentPage === this.totalPages
  }
  get pages(): Array<{ name: number; isDisabled: boolean }> {
    const range = []
    for (let i = this.startPage; i <= this.endPage; i += 1) {
      range.push({
        name: i,
        isDisabled: i === this.currentPage
      })
    }
    return range
  }

  onClickFirstPage(): void {
    this.$emit('page-changed', 1)
  }
  onClickPreviousPage(): void {
    this.$emit('page-changed', this.currentPage - 1)
  }
  onClickPage(page: number): void {
    this.$emit('page-changed', page)
  }
  onClickNextPage(): void {
    this.$emit('page-changed', this.currentPage + 1)
  }
  onClickLastPage(): void {
    this.$emit('page-changed', this.totalPages)
  }
  isPageActive(page: number): boolean {
    return this.currentPage === page
  }
}
