import { UserType } from '@bertazzoni/common/src/models/user.model'

export enum CategoryObjectType {
  module = 'module',
  moduleContent = 'moduleContent',
  moduleContentEdit = 'moduleContentEdit',
  moduleQuizEdit = 'question',
  moduleQuizConsult = 'questionConsultation',
  content = 'content',
  contentAssociation = 'contentAssociation'
}
export enum ObjectType {
  module = 'module',
  moduleQuiz = 'moduleQuiz',
  moduleQuizEdit = 'question',
  moduleQuizConsult = 'questionConsultation',
  moduleContent = 'moduleContent',
  moduleStat = 'moduleStat',
  moduleContentEdit = 'moduleContentEdit',
  content = 'content',
  contentStat = 'contentStat',
  contentAssociation = 'contentAssociation',
  communications = 'communications',
  tag = 'tag',
  tagStat = 'tagStat',
  user = 'user',
  seller = 'seller',
  countryAdmin = 'countryAdmin',
  distributorRep = 'distributorRep',
  salesRep = 'salesRep',
  entity = 'entity'
}

export class PaginInfo {
  limit: number
  offset: number
  totalRow: number
  currentPage: number

  constructor(limit = 5, offset = 0, totalRow = 0, currentPage = 1) {
    this.limit = limit
    this.offset = offset
    this.totalRow = totalRow
    this.currentPage = currentPage
  }
}

export class CustomTable {
  columns: Array<string>
  displayTitle: boolean
  isPaginated: boolean
  searchAndCreate: boolean
  displayButton: boolean

  constructor(
    columns: Array<string> = ['Title', 'Description'],
    displayTitle = false,
    displayButton = true,
    isPaginated = true,
    searchAndCreate = false
  ) {
    this.columns = columns
    this.displayTitle = displayTitle
    this.isPaginated = isPaginated
    this.displayButton = displayButton
    this.searchAndCreate = searchAndCreate
  }
}
export class UserRight {
  userType: UserType
  crud: string
  languageList: string[]

  constructor(userType: UserType = UserType.seller, crud = '', languageList: string[] = []) {
    this.userType = userType
    this.crud = crud
    this.languageList = languageList
  }
}

export class TableInfo {
  paginInfo: PaginInfo
  customTable: CustomTable
  userRight: UserRight

  constructor(
    paginInfo: PaginInfo = {
      limit: 5,
      offset: 0,
      totalRow: 0,
      currentPage: 1
    },
    customTable: CustomTable = {
      columns: ['Title', 'Description'],
      displayTitle: false,
      isPaginated: true,
      searchAndCreate: false,
      displayButton: true
    },
    userRight: UserRight = {
      userType: UserType.seller,
      crud: '',
      languageList: []
    }
  ) {
    this.paginInfo = paginInfo
    this.customTable = customTable
    this.userRight = userRight
  }

  public getPaginInfo() {
    return this.paginInfo
  }
  public getCustomTable() {
    return this.customTable
  }
  public getUserRight() {
    return this.userRight
  }
}
