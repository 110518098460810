import { render, staticRenderFns } from "./PaginatedTable.vue?vue&type=template&id=32ea4d65&scoped=true&"
import script from "./PaginatedTable.vue?vue&type=script&lang=ts&"
export * from "./PaginatedTable.vue?vue&type=script&lang=ts&"
import style0 from "./PaginatedTable.vue?vue&type=style&index=0&id=32ea4d65&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ea4d65",
  null
  
)

export default component.exports